import React, { useMemo, useCallback } from 'react';
import { graphql } from 'gatsby';

import { Page } from 'components/_shared/Page';
import { Section } from 'components/_shared/Section';
import { Tabs } from 'components/_shared/Tabs';
import { VideoList } from 'components/Video';
import { EventList } from 'components/Event';

import { useStrapiVideos } from 'hooks';
import { sortByKey } from 'utils';

import 'styles/PageCircleVideos.styles.scss';

function findById(array, id, idKey = 'id') {
  return array.find(item => item[idKey] === id);
}

export default function PageCircleVideos(props) {
  const { page, events, sections, tabs } = props.data;
  const { circleVideos } = useStrapiVideos();

  const items = useMemo(
    () => ({
      videos: circleVideos,
      events: events.nodes,
      sections: sections.nodes
    }),
    [circleVideos, events, sections]
  );

  const itemKeys = useMemo(() => Object.keys(items), [items]);

  const findMediaByTypeAndId = useCallback(
    (type, id) => findById(items[type], id),
    [items]
  );

  const renderItem = useCallback(
    item => {
      let children = [];

      if (item?.sections?.length) {
        children = [
          ...children,
          ...item.sections.map(section => {
            const _section = findMediaByTypeAndId('sections', section.id);

            return (
              <Section
                key={section.id}
                className='circle-videos-section'
                heading={_section?.heading}
                subheading={_section?.description}
              >
                {renderItem(_section)}
              </Section>
            );
          })
        ];
      }

      if (item?.videos?.length) {
        const videos = sortByKey(
          item.videos.map(({ id }) => findMediaByTypeAndId('videos', id))
        );

        if (!videos?.length) return null;

        const variant = !item?.sections?.length && 'primary';

        children.push(
          <VideoList
            key='videos'
            videos={videos}
            variant={variant}
            withRestriction
          />
        );
      }

      if (item?.events?.length) {
        const events = item.events.map(({ id }) => {
          return findMediaByTypeAndId('events', id);
        });

        if (!events?.length) return null;

        children.push(
          <EventList
            key='events'
            events={sortByKey(events, { key: 'event_date' })}
          />
        );
      }

      return <>{children}</>;
    },
    [findMediaByTypeAndId]
  );

  const _tabs = useMemo(() => {
    return sortByKey(tabs.nodes).reduce((tabs, tab) => {
      if (itemKeys.every(key => !tab[key]?.length)) return tabs;
      return { ...tabs, [tab.label]: renderItem(tab) };
    }, {});
  }, [tabs.nodes, itemKeys, renderItem]);

  return (
    <Page
      className='circle-videos'
      meta={{
        title: page.seotitle,
        description: page.seodescription,
        image: page.preview_img?.url
      }}
      withContainer
    >
      <Section
        variant='aside'
        heading={page.page_title}
        subheading={page.page_subheading}
      >
        <Tabs tabs={_tabs} />
      </Section>
    </Page>
  );
}

export const query = graphql`
  query PageVideosQuery {
    page: strapiVideospage {
      page_subheading
      page_title
      seodescription
      seotitle
      preview_image {
        url
      }
    }

    videos: allStrapiCircleVideo {
      nodes {
        id
        publishedAt
        restricted
        order
        video_title
        video_description
        video_url
        video_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }

    events: allStrapiEvent {
      nodes {
        id
        publishedAt
        event_date(formatString: "DD MMM YYYY")
        event_descriptiion
        event_key
        event_name
        event_speakers
      }
    }

    sections: allStrapiCircleVideoSection {
      nodes {
        id
        publishedAt
        heading
        description
        videos: circle_videos {
          id
        }
      }
    }

    tabs: allStrapiCircleVideoTab {
      nodes {
        publishedAt
        label
        order
        sections: circle_video_sections {
          id
        }
        videos: circle_videos {
          id
        }
        events: events {
          id
        }
      }
    }
  }
`;
